import { notification } from 'antd'

export const showNotification = (type, message, description) => {
  notification[type]({
    message,
    description,
  })
}

export const gtagEvent = ({ action, category, label, value }) => {
  window.gtag('event', action, {
    event_category: category,
    event_label: label,
    value,
  })
}
