import PrivateRoute from 'components/PrivateRoute'
import { HUB_ROUTE } from 'constants/routeUrls'
import React, { lazy } from 'react'
import { Route, Switch } from 'react-router-dom'

const HubPage = lazy(() => import('pages/hub'))

const NotFoundPage = lazy(() => import('pages/not-found'))

const Routes = () => {
  return (
    <Switch>
      <PrivateRoute exact path={HUB_ROUTE} component={HubPage} />
      <Route component={NotFoundPage} />
    </Switch>
  )
}

export default Routes
