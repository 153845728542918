import 'antd/dist/antd.less'
import 'InMarKit/dist/index.css'
import './common/styles.less'

import React from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux';
import { BrowserRouter as Router } from 'react-router-dom'

import App from './App'
import * as serviceWorker from './serviceWorker'
import store from './store'

const render = () => {
  ReactDOM.render(
    <Provider store={store}>
      <Router>
        <App />
      </Router>
    </Provider>,
    document.getElementById('root'),
  )
}

if (module.hot) {
  // Uncomment this for hot reload
  module.hot.accept('./App', () => {
    render()
  })
}

render()

serviceWorker.unregister()
