import get from 'lodash/get'
import { all, call, put, takeEvery } from 'redux-saga/effects';

import { client } from '../client'
import {
  STATUS_URL,
  USER_URL,
} from '../constants/apiUrls'
import {
  GET_STATUS_CALL,
  GET_USER_CALL,
} from '../constants/appConstants'
import {
  getStatusAsync,
  getStatusFailure,
  getStatusSuccess,
  getUserAsync,
  getUserFailure,
  getUserSuccess,
} from './actions'

const getStatusSuccessData = {
  "success": true,
  "data": {
    "locked": ["Attribution"],
    "notifications": [
      {
        "date": "Feb-12-20",
        "description": "Description",
        "icon": "Audiences"
      },
      {
        "date": "Feb-12-20",
        "description": "Description",
        "icon": "Audiences"
      },
      {
        "date": "Feb-12-20",
        "description": "Description",
        "icon": "Audiences"
      }
    ],
    "statistics": [
      {
        "title": "Audiences",
        "value": 134
      },
      {
        "title": "Campaigns",
        "value": 28
      },
      {
        "suffix": "MM",
        "title": "Visits",
        "value": 14.1
      }
    ]
  }
}

export function* getUser() {
  yield put(getUserAsync());
  const response = yield call(client.get, USER_URL, { data: null });
  const data = get(response, 'data', null);
  if (data) {
    // TODO: Remove once API is working
    data.email = 'demo@inmarket.com';
    let email = get(response, 'data.email', null);
    if (email) {
      yield put(getUserSuccess(data));
      return;
    }
  }

  yield put(getUserFailure());
}

export function* getStatus() {
  yield put(getStatusAsync());

  let response;
  try {
    response = yield call(client.get, STATUS_URL, { data: null });
  } catch(e) {
    // TODO: remove once the API works.
    response = { data: getStatusSuccessData };
  }
  
  const success = get(response, 'data.success', false);
  if (success) {
    const data = get(response, 'data.data');
    if (data) {
      yield put(getStatusSuccess(data));
      return;
    }
  }

  const errors = get(response, 'data.errors');
  yield put(getStatusFailure(errors));
}

function* watchActions() {
  yield takeEvery(GET_STATUS_CALL, getStatus);
  yield takeEvery(GET_USER_CALL, getUser);
}

export function* rootSaga() {
  yield all([
    watchActions(),
  ]);
}
