export const LOG_OUT_USER = 'LOG_OUT_USER'

export const GET_STATUS_CALL = 'GET_STATUS_CALL'
export const GET_STATUS_ASYNC = 'GET_STATUS_ASYNC'
export const GET_STATUS_SUCCESS = 'GET_STATUS_SUCCESS'
export const GET_STATUS_FAILURE = 'GET_STATUS_FAILURE'

export const GET_USER_CALL = 'GET_USER_CALL'
export const GET_USER_ASYNC = 'GET_USER_ASYNC'
export const GET_USER_SUCCESS = 'GET_USER_SUCCESS'
export const GET_USER_FAILURE = 'GET_USER_FAILURE'
