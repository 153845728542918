import get from 'lodash/get';

import {
  GET_STATUS_ASYNC,
  GET_STATUS_FAILURE,
  GET_STATUS_SUCCESS,
  GET_USER_ASYNC,
  GET_USER_FAILURE,
  GET_USER_SUCCESS,
  LOG_OUT_USER,
} from '../constants/appConstants'

export const initialState = {
  loading: false,
  // Undefined means user login status is unknown
  // Null or blank string means we checked the login status and it's not valid
  // Anything else means user is logged in.
  loggedInUser: undefined,
  status: undefined,
}

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_STATUS_SUCCESS: {
      const {
        data: status,
      } = action.payload;

      return {
        ...state,
        status,
        loading: false,
      }
    }
    case LOG_OUT_USER:
      return {
        ...state,
        loggedInUser: undefined,
      }
    case GET_USER_SUCCESS: {
      const loggedInUser = get(action, 'payload.data.email', null);
      return {
        ...state,
        loggedInUser,
        loading: false
      }
    }
    case GET_USER_ASYNC:
    case GET_STATUS_ASYNC:
      return {
        ...state,
        loading: true,
      }
    case GET_STATUS_FAILURE:
      return {
        ...state,
        loading: false,
      }
    case GET_USER_FAILURE:
      return {
        ...state,
        loggedInUser: null,
        loading: false,
      }
    default:
      return state;
  }
}
