import {
  GET_STATUS_ASYNC,
  GET_STATUS_CALL,
  GET_STATUS_FAILURE,
  GET_STATUS_SUCCESS,
  GET_USER_ASYNC,
  GET_USER_CALL,
  GET_USER_FAILURE,
  GET_USER_SUCCESS,
  LOG_OUT_USER,
} from '../constants/appConstants'

export const getStatusCall = () => ({
  type: GET_STATUS_CALL,
});

export const getStatusAsync = () => ({
  type: GET_STATUS_ASYNC,
});

export const getStatusSuccess = data => ({
  type: GET_STATUS_SUCCESS,
  payload: { data },
});

export const getStatusFailure= () => ({
  type: GET_STATUS_FAILURE,
});

export const getUserCall = () => ({
  type: GET_USER_CALL,
});

export const getUserAsync = () => ({
  type: GET_USER_ASYNC,
});

export const getUserSuccess = data => ({
  type: GET_USER_SUCCESS,
  payload: { data },
});

export const getUserFailure= () => ({
  type: GET_USER_FAILURE,
});

export const logoutUser= () => ({
  type: LOG_OUT_USER,
});
