import { applyMiddleware, combineReducers, compose, createStore } from 'redux'
import { composeWithDevTools } from 'redux-devtools-extension';
import createSagaMiddleware from 'redux-saga';

import { reducer } from './reducer'
import { rootSaga } from './saga'

const composeEnhancers = (process.env.NODE_ENV !== 'production' && composeWithDevTools) || compose;

const sagaMiddleware = createSagaMiddleware();

const store = createStore(
  combineReducers({
    app: reducer,
  }),
  composeEnhancers(
    applyMiddleware(sagaMiddleware),
  ),
);

sagaMiddleware.run(rootSaga);

export default store;
