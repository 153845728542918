import axios from 'axios'
import { showNotification } from 'common/utils'
import localforage from 'localforage'
import { get } from 'lodash'

export const client = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  headers: {
    'Content-Type': 'application/json',
    Accept: 'application/json',
  },
})

client.defaults.xsrfCookieName = 'CSRF-TOKEN'
client.defaults.xsrfHeaderName = 'X-CSRF-Token'
client.defaults.withCredentials = true

// Set the AUTH token for any request
client.interceptors.request.use(async config => {
  try {
    const token = await localforage.getItem('token')

    if (token) {
      config.headers.Authorization = `Bearer ${token}`
    }
  } catch (error) {
    console.error('Error loading token from localforage')
  }

  return config
})

client.interceptors.response.use(
  response => {
    // Dispatch request completed
    return response
  },
  async error => {
    const status = get(error, 'response.status', 0)
    const requestUrl = get(error, 'response.config.url', '')
    const excludedUrls = ['/users/sign_in'].some(url =>
      requestUrl.includes(url),
    )

    if (status === 401 && !excludedUrls) {
      await localforage.removeItem('email')
      await localforage.removeItem('token')
      await localforage.removeItem('user')

      showNotification(
        'error',
        'Session expired',
        'Your session has been expired please login again',
      )
    }

    return Promise.reject(error)
  },
)
