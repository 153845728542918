import { Spin } from 'antd'
import { AppLayout } from 'components'
import localforage from 'localforage'
import { extendPrototype } from 'localforage-observable/dist/localforage-observable'
import PropTypes from 'prop-types'
import React, { Suspense } from 'react'
import { compose } from 'redux'
import Routes from 'routes'
import { logoutUser } from 'store/actions'
import { mapDispatchToProps } from 'store/redux'
import Observable from 'zen-observable'

const App = (props) => {
  const {
    logout,
  } = props;

  extendPrototype(localforage)
  localforage.newObservable.factory = subscribe => {
    return new Observable(subscribe)
  }

  localforage.ready(() => {
    const observable = localforage.newObservable({
      key: 'email',
    })
    observable.subscribe({
      next: ({ newValue }) => {
        if (!newValue) {
          logout();
        }
      },
    })
  })

  return (
    <Suspense
      fallback={
        <Spin
          className="page-loading-indicator"
          size="large"
          spinning
          tip="Loading..."
        />
      }
    >
      <AppLayout>
        <Routes />
      </AppLayout>
    </Suspense>
  )
}

App.propTypes = {
  logout: PropTypes.func.isRequired,
}

export default compose(
  mapDispatchToProps(dispatch => ({
    logout: () => dispatch(logoutUser()),
  })),
)(App);
